import * as React from "react";
import { style, classes } from "./book-colophon.st.css";

export interface BookColophonProps {
  publicationYear?: string;
  publicationTitle?: string;
  publisherSeriesLabel?: string;
  publisherSeriesDescription?: string;
  publisherSeriesIndex?: number;
  publisherName?: string;
  author?: string;
  serialNumberType?: string;
  serialNumberCode?: string;
  coverPhotoLabel?: string;
  coverPhotoAuthor?: string;
  coverPhotoCreatedAt?: string;
  isCoverPhotoDetail?: string;
  editorCreditLabel?: string;
  editorCreditName?: string;
}

const BookColophon = (props: BookColophonProps) => {
  const {
    publicationYear,
    publicationTitle,
    publisherSeriesLabel,
    publisherSeriesDescription,
    publisherSeriesIndex,
    publisherName,
    author,
    serialNumberType,
    serialNumberCode,
    coverPhotoLabel,
    coverPhotoAuthor,
    coverPhotoCreatedAt,
    isCoverPhotoDetail,
    editorCreditLabel,
    editorCreditName,
  } = props;

  const getSeriesSegment = () => {
    if (!publisherSeriesLabel) {
      return null;
    }

    return <span>
      <span>&nbsp;/&nbsp;</span>
      <span className={style(classes.listVal)}>
        {publisherSeriesLabel}</span> &mdash; {publisherSeriesDescription} {publisherSeriesIndex}
    </span>;
  };

  return <ul className={style(classes.list)}>

    <li className={style(classes.listItem)}>
      <span className={style(classes.title)}>{publicationTitle}</span>
      <span>&nbsp;/&nbsp;</span>
      <span className={style(classes.listVal)}>{author}</span>
    </li>

    <li className={style(classes.listItem)}>
      <span><span className={style(classes.publisherName)}>{publisherName}</span>, {publicationYear}</span>
      {getSeriesSegment()}
    </li>

    {editorCreditName && editorCreditLabel ? <li className={style(classes.listItem)}>
      <span className={style(classes.editorCreditLabel)}>{editorCreditLabel}</span>
      <span>:&nbsp;</span>
      <span className={style(classes.editorCreditName)}>{editorCreditName}</span>
    </li> : null}

    <li className={style(classes.listItem)}>
      <span className={style(classes.creditKey)}>על הכריכה</span>
      <span>:&nbsp;</span>
      <span className={style(classes.coverPhotoLabel)}>{coverPhotoLabel}</span>
      <span>&nbsp;</span>
      <span className={style(classes.photoDetailLabel)}>{isCoverPhotoDetail}</span>
      <span>,&nbsp;</span>
      <span className={style(classes.coverPhotoAuthor)}>{coverPhotoAuthor}</span>
      <span>,&nbsp;</span>
      <span>{coverPhotoCreatedAt}</span>
    </li>

    <li className={style(classes.listItem)}>
      <span className={style(classes.creditKey)}>{serialNumberType}</span>
      <span>:&nbsp;</span>
      <span className={style(classes.creditValue)}>{serialNumberCode}</span>
    </li>
  </ul>;
};

export default BookColophon;
