import * as React from "react";
import * as template from "lodash.template";
import { style, classes } from "./book-product-preview.st.css";

export interface BookProductPreviewProps {
  content: string;
  title?: string;
}

const BookProductPreview = (props: BookProductPreviewProps) => {
  const { content } = props;
  const compiled = template(content);
  const node = compiled(props);
  return <p className={style(classes.root)} dangerouslySetInnerHTML={{__html: node}}></p>;
};

export default BookProductPreview;
