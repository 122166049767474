import * as React from "react";
import { withLayout } from "../components/Layout";
import BookReviews from "../components/book-reviews/book-reviews";
import Button, { ButtonTypes } from "../components/Button/Button";
import BookColophon from "../components/book-colophon/book-colophon";
import BookProductPreview from "../components/book-product-preview/book-product-preview";
import leftArrowCircle from "../css/svg/arrow-circle-left.svg";
// import Link from "gatsby-link";
import { style, classes } from "./loremipsum.st.css";

import coverPhoto from "../images/lorem-ipsum-book-cover-front.jpg";
import { BookReviewProps } from "../types";

const reviews = [
  {
    author: "דניאל עוז",
    id: "1",
    source: "ynet",
    text: "ראוי לציון כספר דיסידנטי באמת",
    url: "http://www.ynet.co.il/articles/0,7340,L-3825723,00.html",
  },
  {
    author: "דרור בורשטיין",
    id: "2",
    source: "הארץ",
    text: "הספר הזה הוא ניסיון לחשוב \"שירה\" במציאות לא-שירית, מציאות של הבזקי מידע",
    url: "http://www.haaretz.co.il/captain/net/1.1464245",
  },
  {
    author: "ורד אריאלי",
    id: "3",
    source: "88fm",
    text: "הטובים שבקטעי הספר מדגימים את יכולת הקידוד בדברים שהמשמעות שלהם חורגת מעבר למראה הרזה-רזה",
    // url: "http://tomerlichtash.com/assets/audio/loremipsum/vered-arieli-88fm.mp3",
  },
  {
    author: "אלי הירש",
    id: "4",
    source: "ידיעות אחרונות",
    text: "מקורה של הפואטיקה של ליכטש היא באינטרנט, בעיקר באומנות הצ'ט והטוקבק",
    url: "http://elihirsh.com/?p=521",
  },
];

const randomize = (items: BookReviewProps[]) => {
  let currentIndex = items.length;
  let temporaryValue;
  let randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = items[currentIndex];
    items[currentIndex] = items[randomIndex];
    items[randomIndex] = temporaryValue;
  }
  return items;
};

const LoremIpsumPage = (props: any) => {
  return (
    <div className={style(classes.root)}>
      <div className={style(classes.topFold)}>
        <div className={style(classes.container)}>
          <div className={style(classes.grid)}>
            <div className={style(classes.row)}>
              <div className={style(classes.column, { stackable: true, columns: 8 })}>
                <div className={style(classes.imageFrame, { center: true })}>
                  <img
                    title={`לורם איפסום &mdash; כריכת הספר`}
                    src={coverPhoto}
                    className={style(classes.coverPhoto)}
                  />
                </div>
              </div>

              <div className={style(classes.column, { stackable: true, columns: 8 })}>
                <BookProductPreview
                  title={`לורם איפסום`}
                  content={`הספר <em><%= title %></em> הוא מאשאפ בן 507 קטעים בני שלוש שורות.
                   הספר יצא לאור בשנת 2007 בהוצאה עצמית,
                  במהדורת דפוס מצומצמת ובמהדורה דיגיטלית חינמית, וזמין כאן להורדה.`}
                />
                <Button
                  type={ButtonTypes.HREF}
                  className={style(classes.ctaButton)}
                  href={`https://github.com/tomerlichtash/public/raw/master/loremipsum.pdf`}
                  target={`_blank`}
                  label={`להורדת מהדורה דיגיטלית (PDF)`}
                  icon={leftArrowCircle}
                />
                <BookColophon
                  publicationYear={`2007`}
                  publicationTitle={`לורם איפסום`}
                  publisherName={`הוצאת המחבר`}
                  author={`תומר ליכטש`}
                  serialNumberType={`דאנאקוד (Danacode)`}
                  serialNumberCode={`800-3709`}
                  coverPhotoLabel={`Empire of Syntagm`}
                  coverPhotoAuthor={`אנדרו מק׳ריי`}
                  coverPhotoCreatedAt={`2006`}
                  isCoverPhotoDetail={`פרט`}
                />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className={style(classes.container)}>
        <div className={style(classes.reviews)}>
          <BookReviews reviews={randomize(reviews)} />
        </div>
      </div>
    </div>
  );
};

export default withLayout(LoremIpsumPage);
