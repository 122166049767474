import * as React from "react";
import { BookReviewProps } from "../../types";
import { style, classes } from "./book-review.st.css";

export const BookReview = (props: BookReviewProps) => {
  const {
    author,
    url,
    source,
    text,
  } = props;

  const sourceText = <span>
    <span className={style(classes.author)}>{author}</span>
    <span className={style(classes.divider)}>/</span>
    <span className={style(classes.source)}>{source}</span>
  </span>;

  const sourceWithUrl = <a href={url} target="_blank">
    {sourceText}
  </a>;

  return (
    <div className={style(classes.root)}>
      <div className={style(classes.blockquote)}>
        <p>{text}</p>
      </div>
      <div className={style(classes.small)}>
        {url ? sourceWithUrl : sourceText}
      </div>
    </div>
  );
};

export default BookReview;
