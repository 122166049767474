import * as React from "react";
import BookReview from "../book-review/book-review";
import { BookReviewProps } from "../../types";
import { style, classes } from "./book-reviews.st.css";

export interface BookCoverProps {
  reviews: BookReviewProps[];
}

const getReviews = (reviews: BookReviewProps[]) => {
  return reviews.map((review: BookReviewProps, index: number) =>
    <BookReview key={`reviewKey_${index}`} {...review} />,
  );
};

const BookReviews = (props: BookCoverProps) => {
  const { reviews } = props;

  if (!reviews || !reviews.length) {
    return null;
  }

  return <ul className={style(classes.items)}>
    {getReviews(reviews)}
  </ul>;
};

export default BookReviews;
